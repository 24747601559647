<template>
  <v-app id="inspire">
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-12">
              <h1 class="text-center mt-6">
                DMIS
              </h1>
              <v-snackbar
                v-model="snackbar"
                centered
                :color="color"
                :timeout="timeout"
              >
                {{ text }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="#4268b1"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
              <p class="text-center font-weight-light font-italic mt-10">Provide your username and email to reset your password ...</p>
              <p class="text-center font-weight-light font-italic">Note: Email and username should be known by DMIS ...</p>
              <p class="text-center font-weight-light font-italic cb">{{message}}</p>
              <v-card-text>
                <v-form>
                  <v-text-field
                    v-model="email"
                    label="Email"
                    name="email"
                    type="text"
                    outlined
                    filled
                  />
                  <v-text-field
                    v-model="username"
                    label="Username"
                    name="username"
                    type="text"
                    outlined
                    filled
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="#4268b1"
                  width="100px"
                  @click="requestToken"
                >
                  Submit
                </v-btn>
                <v-spacer />
                <v-btn
                  outlined
                  link
                  text
                  @click="backToLogin"
                >
                  Back to Login
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data () {
    return {
      username: '',
      email: '',
      snackbar: false,
      color: null,
      text: null,
      timeout: 10000,
      message: ''
    }
  },

  methods: {

    requestToken () {
      this.$authenticationService.requestToken(this.username, this.email)
        .then(response => {
          if (response.status === 'CREATED') {
            this.errorMessage = null
            this.alert = false
            // this.$router.push('/password-reset')
            this.message = 'An email has been sent with a link to reset your password'
            setTimeout(() => {
              this.backToLogin()
            }, 6000)
          } else {
            this.snackbar = true
            this.text = 'Error! ' + response.message
            this.color = 'red'
          }
        })
    },
    backToLogin () {
      this.$router.push('/login')
    }
    // setUsername (event) {
    //   this.username = this.email.split('@')[0]
    // }
  }

}

</script>
<style >

.cb {
  color: green;
  font-size: 20px;
}

</style>
